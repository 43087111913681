@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f6f8ff;
  margin: 0;
}

.dash-container {
  max-width: 480px;
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

.text-paragraph-14 {
  color: #9498ac;
  font-size: 14px;
}

.text-paragraph-13 {
  color: #9498ac;
  font-size: 13px;
}

.text-paragraph-12 {
  color: #9498ac;
  font-size: 12px;
}

.text-black-title {
  color: #0e132d;
  font-size: 14px;
}

.text-black-title-18 {
  color: #0e132d;
  font-size: 18px;
  font-weight: 600;
}

.text-black-title-16 {
  color: #0e132d;
  font-size: 16px;
}

.text-big-title {
  color: #0e132d;
  font-weight: bold;
  font-size: 20px;
}

.text-primary-blue {
  color: #304fc1;
}

.bg-primary-blue {
  background-color: #304fc1;
}

.border-primary-blue {
  border: 1px solid #304fc1;
}

.text-help-12 {
  color: #0e132d;
}

.absolute-tengah {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
